var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container CollegeManagement" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "32px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { staticClass: "btn", attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 6, lg: 6, xl: 5 } },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("student-level", {
                              on: { studentLevel: _vm.studentLevel },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 6, lg: 6, xl: 5 } },
                      [
                        _vm._v(
                          "\n                    学员来源：\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              filterable: "",
                              placeholder: "请搜索花名",
                              clearable: "",
                            },
                            on: { change: _vm.flowerArrayEs },
                            model: {
                              value: _vm.source_client_id,
                              callback: function ($$v) {
                                _vm.source_client_id = $$v
                              },
                              expression: "source_client_id",
                            },
                          },
                          _vm._l(_vm.flower_Array, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.flower_name,
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 8, lg: 6, xl: 6 } },
                      [
                        _vm._v(
                          "\n                    跟进人所属部门：\n                    "
                        ),
                        _c("el-cascader", {
                          attrs: {
                            options: _vm.departmentEvel,
                            size: "small",
                            clearable: "",
                            "collapse-tags": "",
                            props: {
                              value: "id",
                              label: "realname",
                              multiple: true,
                            },
                          },
                          on: { change: _vm.departmentChange },
                          model: {
                            value: _vm.department,
                            callback: function ($$v) {
                              _vm.department = $$v
                            },
                            expression: "department",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { float: "right" },
                        attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "请搜索电话、名称",
                              size: "small",
                            },
                            model: {
                              value: _vm.ClassSearch,
                              callback: function ($$v) {
                                _vm.ClassSearch = $$v
                              },
                              expression: "ClassSearch",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.ClassSearchInput },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          float: "right",
                          "padding-right": "0",
                          "margin-top": "2px",
                        },
                        attrs: { xs: 6, sm: 6, md: 5, lg: 3, xl: 3 },
                      },
                      [
                        _c("display-settingser", {
                          staticClass: "ml10 filter-item",
                          staticStyle: { float: "right" },
                          attrs: {
                            list_type: _vm.list_typeE,
                            resultList: _vm.resultES,
                            "display-arr": _vm.displaySettingsWhole,
                            "display-value": _vm.displaySettings,
                          },
                          on: {
                            "update:displayValue": function ($event) {
                              _vm.displaySettings = $event
                            },
                            "update:display-value": function ($event) {
                              _vm.displaySettings = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "btn",
                    staticStyle: { "margin-top": "20px" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        { staticClass: "operation" },
                        [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["回归公海"],
                                  expression: "['回归公海']",
                                },
                              ],
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.regression },
                            },
                            [_vm._v("回归公海")]
                          ),
                          _vm._v(" "),
                          _vm.projectBtnShow
                            ? _c(
                                "el-dropdown",
                                { staticClass: "mb20" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: ["转移跟进人"],
                                          expression: "['转移跟进人']",
                                        },
                                      ],
                                      staticClass: " marginLeft",
                                      attrs: { type: "primary", size: "small" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                转移\n                                "
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "请选择转移的人员",
                                                placement: "right",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value: ["转移跟进人"],
                                                      expression:
                                                        "['转移跟进人']",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    border: "none",
                                                  },
                                                  on: {
                                                    click: _vm.followUpPerson,
                                                  },
                                                },
                                                [_vm._v("转移跟进人")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                title: "共享客户：",
                                width: "300",
                                trigger: "hover",
                                content:
                                  "当前有权限的人员列表：(说明：同部门之间不能共享客户（避免重复数据），同部门之间只能转移客户)",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["共享"],
                                      expression: "['共享']",
                                    },
                                  ],
                                  staticClass: " marginLeft",
                                  attrs: {
                                    slot: "reference",
                                    type: "primary",
                                    size: "small",
                                  },
                                  on: { click: _vm.share },
                                  slot: "reference",
                                },
                                [_vm._v("共享")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["外送公海池"],
                                  expression: "['外送公海池']",
                                },
                              ],
                              staticClass: "marginLeft",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.CustomerDeliverIndex },
                            },
                            [_vm._v("外送公海池")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["员工/部门"],
                                  expression: "['员工/部门']",
                                },
                              ],
                              staticClass: "marginLeft",
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.staffDepartment },
                            },
                            [_vm._v("员工/部门")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown",
                            { staticClass: "mb20" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: " marginLeft",
                                  attrs: { type: "primary", size: "small" },
                                },
                                [
                                  _vm._v(
                                    "\n                                更多功能\n                                "
                                  ),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-down el-icon--right",
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c("el-dropdown-item", [
                                    _c(
                                      "span",
                                      { on: { click: _vm.delCustomer } },
                                      [_vm._v("删除")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.FollowUpShow,
                              expression: "FollowUpShow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("跟进人："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                placeholder: "请搜索跟进人",
                                size: "small",
                              },
                              on: { change: _vm.FollowUpClick },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.FollowUpClick($event)
                                },
                              },
                              model: {
                                value: _vm.FollowUpEvel,
                                callback: function ($$v) {
                                  _vm.FollowUpEvel = $$v
                                },
                                expression: "FollowUpEvel",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.FollowUpClick },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("跟进人")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.createshow,
                              expression: "createshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("创建人:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                placeholder: "请搜索创建人",
                                size: "small",
                              },
                              on: { change: _vm.creater },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.creater($event)
                                },
                              },
                              model: {
                                value: _vm.create_realname,
                                callback: function ($$v) {
                                  _vm.create_realname = $$v
                                },
                                expression: "create_realname",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.creater },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("创建人")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.labelpeersshow,
                              expression: "labelpeersshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "nameTitle projectScreening" },
                            [_vm._v("客户标签：")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                size: "small",
                                placeholder: "请选择",
                                clearable: "",
                              },
                              on: { change: _vm.customerLabel },
                              model: {
                                value: _vm.labelpeers,
                                callback: function ($$v) {
                                  _vm.labelpeers = $$v
                                },
                                expression: "labelpeers",
                              },
                            },
                            _vm._l(_vm.labelpeersEs, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.value, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("客户标签")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.noteshow,
                              expression: "noteshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("备注:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                placeholder: "请搜索备注",
                                size: "small",
                              },
                              on: { change: _vm.notes },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.notes($event)
                                },
                              },
                              model: {
                                value: _vm.remark,
                                callback: function ($$v) {
                                  _vm.remark = $$v
                                },
                                expression: "remark",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.notes },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("备注")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.structure_idshow,
                              expression: "structure_idshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "nameTitle projectScreening" },
                            [_vm._v("组织部门：")]
                          ),
                          _vm._v(" "),
                          _c("el-cascader", {
                            attrs: {
                              placeholder: "请选择部门",
                              options: _vm.bumenoptions,
                              props: {
                                value: "id",
                                label: "name",
                                multiple: true,
                              },
                              size: "small",
                              "collapse-tags": "",
                              clearable: "",
                            },
                            on: { change: _vm.structureclick },
                            model: {
                              value: _vm.structure_id,
                              callback: function ($$v) {
                                _vm.structure_id = $$v
                              },
                              expression: "structure_id",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("组织部门")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ittnzyshow,
                              expression: "ittnzyshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "nameTitle projectScreening" },
                            [_vm._v("意向专业：")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择",
                                size: "small",
                                clearable: "",
                              },
                              on: { change: _vm.shouwittnzyclick },
                              model: {
                                value: _vm.showittnzy,
                                callback: function ($$v) {
                                  _vm.showittnzy = $$v
                                },
                                expression: "showittnzy",
                              },
                            },
                            _vm._l(_vm.Intendeds, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("意向专业")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.createtimeshow,
                              expression: "createtimeshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("创建日期:"),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: {
                              width: "225px !important",
                              "margin-left": "20px",
                            },
                            attrs: {
                              size: "small",
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "  结束日期",
                              "value-format": "timestamp",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            on: { change: _vm.DateTime },
                            model: {
                              value: _vm.value1,
                              callback: function ($$v) {
                                _vm.value1 = $$v
                              },
                              expression: "value1",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("创建日期")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.finalFollowshow,
                              expression: "finalFollowshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("最后跟进日期:"),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "234px !important" },
                            attrs: {
                              size: "small",
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "  结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "timestamp",
                            },
                            on: { change: _vm.FinalFollowUpEs },
                            model: {
                              value: _vm.finalFollow,
                              callback: function ($$v) {
                                _vm.finalFollow = $$v
                              },
                              expression: "finalFollow",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("最后跟进日期")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.nexttimeshow,
                              expression: "nexttimeshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "nameTitle projectScreening" },
                            [_vm._v("下次回访时间：")]
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px !important" },
                            attrs: {
                              size: "small",
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "  结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "timestamp",
                            },
                            on: { change: _vm.nextDateTime },
                            model: {
                              value: _vm.nexttime,
                              callback: function ($$v) {
                                _vm.nexttime = $$v
                              },
                              expression: "nexttime",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("下次回访日期")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.callNumShow,
                                expression: "callNumShow",
                              },
                            ],
                            staticClass: "call_num",
                            staticStyle: {
                              "margin-top": "15px",
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _c(
                                "span",
                                { staticClass: "nameTitle projectScreening" },
                                [_vm._v("拨打次数：")]
                              ),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c("el-input", {
                                  attrs: { size: "small", type: "number" },
                                  model: {
                                    value: _vm.sNum,
                                    callback: function ($$v) {
                                      _vm.sNum = $$v
                                    },
                                    expression: "sNum",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "line",
                                staticStyle: { padding: "0", width: "8px" },
                                attrs: { span: 1 },
                              },
                              [_vm._v("-")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { size: "small", type: "number" },
                                    model: {
                                      value: _vm.eNum,
                                      callback: function ($$v) {
                                        _vm.eNum = $$v
                                      },
                                      expression: "eNum",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        slot: "append",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.callNums },
                                      slot: "append",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("拨打次数")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        border: "",
                        id: "customerTable",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                        },
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChange,
                        "sort-change": _vm.sortEs,
                      },
                    },
                    [
                      _c("el-table-column", {
                        key: 0,
                        attrs: { type: "selection", align: "center" },
                      }),
                      _vm._v(" "),
                      _vm.displaySettings.includes("序号")
                        ? _c("el-table-column", {
                            key: 1,
                            attrs: {
                              label: "序号",
                              align: "center",
                              width: "50",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.$index +
                                              (_vm.query.page - 1) *
                                                _vm.query.pagesize +
                                              1
                                          ) + " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3093164208
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("置顶")
                        ? _c("el-table-column", {
                            key: 2,
                            attrs: { label: "置顶", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.is_top == 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.topOfList(
                                                    "1",
                                                    scope.row.customer_data_id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("置顶")]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                background: "#ffb400",
                                                "border-color": "#ffb400",
                                              },
                                              attrs: {
                                                type: "primary",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.topOfList(
                                                    "0",
                                                    scope.row.customer_data_id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("取消置顶")]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1419194492
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("客户名称")
                        ? _c("el-table-column", {
                            key: 3,
                            attrs: { label: "客户名称", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1890FF" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goTodetails(
                                                scope.row,
                                                scope
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.cname)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3410089491
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("电话号码")
                        ? _c("el-table-column", {
                            key: 4,
                            attrs: {
                              label: "电话号码",
                              prop: "mobile",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("归属地")
                        ? _c("el-table-column", {
                            key: 5,
                            attrs: {
                              label: "归属地",
                              prop: "mobile_address",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("微信号码")
                        ? _c("el-table-column", {
                            key: 6,
                            attrs: {
                              label: "微信号码",
                              prop: "wxnum",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("跟进人")
                        ? _c(
                            "el-table-column",
                            {
                              key: 7,
                              attrs: {
                                label: "跟进人",
                                prop: "follow_realname",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("跟进人")
                                      },
                                    },
                                  },
                                  [_vm._v("跟进人")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("跟进人所属部门")
                        ? _c("el-table-column", {
                            key: 45,
                            attrs: {
                              label: "跟进人所属部门",
                              prop: "follow_structure",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("最后跟进日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 8,
                              attrs: {
                                label: "最后跟进时间",
                                prop: "follw_time",
                                align: "center",
                                sortable: "custom",
                                width: "150",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("最后跟进日期")
                                      },
                                    },
                                  },
                                  [_vm._v("最后跟进日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("学员级别")
                        ? _c("el-table-column", {
                            key: 9,
                            attrs: {
                              label: "学员级别",
                              prop: "customer_level",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("学术成绩")
                        ? _c("el-table-column", {
                            key: 10,
                            attrs: {
                              label: "学术成绩",
                              prop: "academic_achievements",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("跟进")
                        ? _c("el-table-column", {
                            key: 11,
                            attrs: { label: "跟进", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          key: 12,
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                            align: "center",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.followUpCustomers(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "跟进(" +
                                              _vm._s(scope.row.follow_num) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              215198317
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("创建人")
                        ? _c(
                            "el-table-column",
                            {
                              key: 13,
                              attrs: {
                                label: "创建人",
                                prop: "create_realname",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("创建人")
                                      },
                                    },
                                  },
                                  [_vm._v("创建人")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("创建日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 14,
                              attrs: {
                                label: "创建日期",
                                prop: "create_time",
                                align: "center",
                                sortable: "custom",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("创建日期")
                                      },
                                    },
                                  },
                                  [_vm._v("创建日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("下次回访日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 15,
                              attrs: {
                                label: "下次回访日期",
                                prop: "next_time",
                                align: "center",
                                sortable: "custom",
                                width: "150",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("下次回访日期")
                                      },
                                    },
                                  },
                                  [_vm._v("下次回访日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("报考项目")
                        ? _c("el-table-column", {
                            key: 16,
                            attrs: {
                              label: "报考项目",
                              prop: "ittnxm",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("客户标签")
                        ? _c(
                            "el-table-column",
                            {
                              key: 17,
                              attrs: {
                                label: "客户标签",
                                prop: "labelpeer",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("客户标签")
                                      },
                                    },
                                  },
                                  [_vm._v("客户标签")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("QQ号")
                        ? _c("el-table-column", {
                            key: 18,
                            attrs: {
                              label: "QQ号",
                              prop: "qq",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("年龄")
                        ? _c("el-table-column", {
                            key: 19,
                            attrs: {
                              label: "年龄",
                              prop: "age",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("备注")
                        ? _c(
                            "el-table-column",
                            {
                              key: 20,
                              attrs: { label: "备注", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "itemDes",
                                            attrs: {
                                              content: scope.row.notes,
                                              "popper-class": "toolp",
                                              placement: "top-start",
                                              effect: "light",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "dib ell",
                                                staticStyle: { width: "48px" },
                                              },
                                              [_vm._v(_vm._s(scope.row.notes))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3724436026
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("备注")
                                      },
                                    },
                                  },
                                  [_vm._v("备注")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("岗位")
                        ? _c("el-table-column", {
                            key: 21,
                            attrs: {
                              label: "岗位",
                              prop: "post",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("联系电话2")
                        ? _c("el-table-column", {
                            key: 22,
                            attrs: {
                              label: "联系电话2",
                              prop: "mobile1",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("归属地2")
                        ? _c("el-table-column", {
                            key: 23,
                            attrs: {
                              label: "归属地2",
                              prop: "mobile1_address",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("联系电话3")
                        ? _c("el-table-column", {
                            key: 24,
                            attrs: {
                              label: "联系电话3",
                              prop: "mobile2",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("归属地3")
                        ? _c("el-table-column", {
                            key: 25,
                            attrs: {
                              label: "归属地3",
                              prop: "mobile2_address",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向院校")
                        ? _c("el-table-column", {
                            key: 26,
                            attrs: {
                              label: "意向院校",
                              prop: "ittnyx",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("职业")
                        ? _c("el-table-column", {
                            key: 27,
                            attrs: {
                              label: "职业",
                              prop: "occupation",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("行业")
                        ? _c("el-table-column", {
                            key: 28,
                            attrs: {
                              label: "行业",
                              prop: "industry",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向简章")
                        ? _c("el-table-column", {
                            key: 29,
                            attrs: {
                              label: "意向简章",
                              prop: "ittnjz",
                              align: "center",
                              "max-height": "100",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("联系电话2")
                        ? _c("el-table-column", {
                            attrs: { label: "联系电话2", prop: "mobile1" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("当前学历")
                        ? _c("el-table-column", {
                            key: 30,
                            attrs: {
                              label: "当前学历",
                              prop: "graduation_xueli",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("原始创建人")
                        ? _c("el-table-column", {
                            key: 31,
                            attrs: {
                              label: "原始创建人",
                              prop: "old_create_realname",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向地区")
                        ? _c("el-table-column", {
                            key: 32,
                            attrs: {
                              label: "意向地区",
                              prop: "ittngj",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("发起沟通网址")
                        ? _c("el-table-column", {
                            key: 33,
                            attrs: {
                              label: "发起沟通网址",
                              prop: "communication_website",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("语言成绩")
                        ? _c("el-table-column", {
                            key: 34,
                            attrs: {
                              label: "语言成绩",
                              prop: "language_achievement",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("预算")
                        ? _c("el-table-column", {
                            key: 35,
                            attrs: {
                              label: "预算",
                              prop: "budget",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("座机")
                        ? _c("el-table-column", {
                            key: 36,
                            attrs: {
                              label: "座机",
                              prop: "telephone",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("毕业院校")
                        ? _c("el-table-column", {
                            key: 37,
                            attrs: {
                              label: "毕业院校",
                              prop: "graduate_school",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("组织部门")
                        ? _c(
                            "el-table-column",
                            {
                              key: 38,
                              attrs: {
                                label: "组织部门",
                                prop: "structure",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("组织部门")
                                      },
                                    },
                                  },
                                  [_vm._v("组织部门")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("邮箱")
                        ? _c("el-table-column", {
                            key: 39,
                            attrs: {
                              label: "邮箱",
                              prop: "cemail",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("毕业专业")
                        ? _c("el-table-column", {
                            key: 40,
                            attrs: {
                              label: "毕业专业",
                              prop: "graduation_major",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("护照号码")
                        ? _c("el-table-column", {
                            key: 41,
                            attrs: {
                              label: "护照号码",
                              prop: "passport_number",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("学员来源")
                        ? _c("el-table-column", {
                            key: 42,
                            attrs: {
                              label: "学员来源",
                              prop: "client_name",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向专业")
                        ? _c(
                            "el-table-column",
                            {
                              key: 43,
                              attrs: {
                                label: "意向专业",
                                prop: "ittnzy",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("意向专业")
                                      },
                                    },
                                  },
                                  [_vm._v("意向专业")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          key: 61,
                          attrs: {
                            label: "拨打次数",
                            sortable: "custom",
                            width: "120",
                            prop: "all_call_num",
                            align: "center",
                            filters: [],
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.all_call_num > 0
                                      ? _c(
                                          "a",
                                          {
                                            staticStyle: { color: "#1890ff" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewRecord(
                                                  scope.row.customer_id,
                                                  scope.row.cname,
                                                  scope.row.mobile
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(scope.row.all_call_num) +
                                                "次\n                            "
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.all_call_num) +
                                              "次"
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            640902733
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890ff",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("拨打次数")
                                  },
                                },
                              },
                              [_vm._v("拨打次数")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.displaySettings.includes("操作")
                        ? _c("el-table-column", {
                            key: 46,
                            attrs: { label: "操作", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "purple-btn",
                                          attrs: { size: "small" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.makeCall(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("呼叫")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "pink-btn",
                                          attrs: { size: "small" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleAgentBarBtnClick(
                                                "hangup"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("挂断")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3982560549
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("性别")
                        ? _c("el-table-column", {
                            key: 44,
                            attrs: { label: "性别", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.sex == "0" ? "男" : "女"
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4079631929
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "tableLeft",
                      attrs: { id: "tableLeft" },
                      on: {
                        scroll: function ($event) {
                          return _vm.exterHandleScroll()
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticStyle: { height: "10px" },
                        attrs: { id: "bodyLeft" },
                      }),
                    ]
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c("mailList", {
              ref: "staffSelect",
              attrs: {
                radio: _vm.radio,
                condition: _vm.condition,
                visible: _vm.mailListVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mailListVisible = $event
                },
                maillistRadio: _vm.maillistRadio,
              },
              model: {
                value: _vm.staffResult,
                callback: function ($$v) {
                  _vm.staffResult = $$v
                },
                expression: "staffResult",
              },
            }),
            _vm._v(" "),
            _c("mailListEs", {
              ref: "staffSelect",
              attrs: {
                radio: _vm.radioEs,
                condition: _vm.condition,
                visible: _vm.mailListVisibleEs,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mailListVisibleEs = $event
                },
                staffSelectConfirmEs: _vm.staffSelectConfirmEs,
              },
              model: {
                value: _vm.staffResultEs,
                callback: function ($$v) {
                  _vm.staffResultEs = $$v
                },
                expression: "staffResultEs",
              },
            }),
            _vm._v(" "),
            _c("mailListment", {
              ref: "staffSelect",
              attrs: {
                radio: _vm.radioment,
                condition: _vm.condition,
                visible: _vm.mailListVisiblement,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mailListVisiblement = $event
                },
                SharedSustomersment: _vm.SharedSustomersment,
              },
              model: {
                value: _vm.staffResultment,
                callback: function ($$v) {
                  _vm.staffResultment = $$v
                },
                expression: "staffResultment",
              },
            }),
            _vm._v(" "),
            _c("mailListData", {
              ref: "staffSelect",
              attrs: {
                radio: _vm.radioData,
                condition: _vm.condition,
                visible: _vm.mailListVisibleData,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mailListVisibleData = $event
                },
                SharedSustomersData: _vm.SharedSustomersData,
              },
              model: {
                value: _vm.staffResultData,
                callback: function ($$v) {
                  _vm.staffResultData = $$v
                },
                expression: "staffResultData",
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "big-dialogs",
                attrs: { title: "跟进", visible: _vm.followUp },
                on: {
                  "update:visible": function ($event) {
                    _vm.followUp = $event
                  },
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-form",
                            {
                              staticStyle: {
                                width: "400px",
                                "margin-left": "50px",
                              },
                              attrs: {
                                "label-position": "right",
                                "label-width": "120px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "客户名称:" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.followUpEs.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.followUpEs, "name", $$v)
                                      },
                                      expression: "followUpEs.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "主题:" } },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: _vm.followUpEs.theme,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.followUpEs, "theme", $$v)
                                      },
                                      expression: "followUpEs.theme",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "类型:" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "Please select" },
                                      model: {
                                        value: _vm.followUpEs.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.followUpEs, "type", $$v)
                                        },
                                        expression: "followUpEs.type",
                                      },
                                    },
                                    _vm._l(
                                      _vm.calendarTypeOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.label,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "下次回访日期:" } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "default-value": new Date(),
                                      type: "datetime",
                                      placeholder: "选择日期时间",
                                    },
                                    model: {
                                      value: _vm.followUpEs.next_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.followUpEs,
                                          "next_time",
                                          $$v
                                        )
                                      },
                                      expression: "followUpEs.next_time",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: { "text-align": "center" },
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.followUp = false
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.NewFollowupRecordSure },
                                },
                                [_vm._v("确认")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("h3", [_vm._v("历史跟进记录:")]),
                        _vm._v(" "),
                        _c(
                          "el-timeline",
                          _vm._l(_vm.activities, function (activity, index) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  placement: "top",
                                  timestamp: activity.create_time,
                                },
                              },
                              [
                                _c("span", { staticClass: "eD8CF0 mr10" }, [
                                  _vm._v(_vm._s(activity.type)),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(activity.create_realnaem)),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(activity.content))]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title:
                    "累计拨打记录 ( " +
                    _vm.cusName +
                    "：" +
                    _vm.cusPhone +
                    " )",
                  visible: _vm.dialogTableVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogTableVisible = $event
                  },
                },
              },
              [
                _vm.dialogTableVisible
                  ? _c("callRecord", { attrs: { cid: _vm.callCid } })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }