import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _methods;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MyLoweIndex, MyCustomerRegression, MyCustomerTop, MyCustomerTransfer, MyShareSetshare, MyCustomerTransfoll, FollwupIndex, FollwupAdd, getLabelpeer as _getLabelpeer, getCustomerLevel, MyCustomerImport, getShareList, CustomerDeliverIndex as _CustomerDeliverIndex, agentFlowerNameList, Majorlist, delCustomer as _delCustomer } from '@/api/ceshi_xiugai/whole';
import { getStructureListArr } from '@/api/PublicAPI';
import { getUserList as _getUserList } from '@/api/updateUserInfo/structure';
import DisplaySettingser from '@/components/DisplaySettings/Settings';
import axios from 'axios';
import { getTokens } from '@/utils/auth';
import Head from '@/components/head/index';
import studentLevel from '@/components/studentLevel/index';
import MyExportExcel from '@/components/MyExportExcel/index';
import mailList from '@/components/StaffSelect/mailListRadio';
import mailListEs from '@/components/StaffSelect/mailListRadioEs';
import mailListment from '@/components/StaffSelect/mailListRadioment';
import mailListData from '@/components/StaffSelect/mailListRadioData';
import callRecord from '@/components/callRecord/index';
export default {
  name: 'CollegeManagement',
  data: function data() {
    var _ref;
    return _ref = {
      resultES: {},
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '全部数据',
      reverse: true,
      val: '',
      FollowUpEvel: '',
      department: [],
      departmentEvel: [],
      departmentName: '',
      follow_name: '',
      //跟进人
      follow_Id: '',
      follow_page: 1,
      //分页
      follow_pagesize: 20,
      //多少条数据
      follow_Arr: [],
      //筛选跟进人数组
      loading: false,
      createtimeshow: false,
      finalFollowshow: false,
      nexttimeshow: false,
      followshow: false,
      FollowUpShow: false,
      createshow: false,
      labelpeersshow: false,
      noteshow: false,
      showittnzy: '',
      structure_idshow: false,
      ittnzyshow: false,
      finalFollow: '',
      nexttime: '',
      //下次回访时间
      next_follow_stime: '',
      next_follow_etime: '',
      create_stime: '',
      create_etime: '',
      create_time_order: 0,
      //创建日期排序
      follow_time_order: 0,
      //最后跟进日期排序
      next_time_order: 0,
      sourceoptions: [],
      source_client_id: '',
      //花名id
      client_name: '',
      create_realname: '',
      //创建人
      labelpeers: '',
      remark: '',
      ittnzy: '',
      Intendeds: [],
      Intended: '',
      //意向专业
      flower_Array: [],
      //花名
      bumenoptions: [],
      //组织部门
      structure_id: '',
      //组织部门
      structure_idEs: ''
    }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "labelpeers", ''), "labelpeersEs", []), "projectBtnShow", true), "activities", []), "query", {
      page: 1,
      pagesize: 10,
      total: 0 //总条数，不是总页数
    }), "followUp", false), "start_sea_time", ''), "end_sea_time", ''), "start_follow_time", ''), "end_follow_time", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "source", [{
      //来源
      value: '0',
      label: '全部'
    }, {
      value: '1',
      label: '录入'
    }, {
      value: '2',
      label: '领取'
    }, {
      value: '3',
      label: '分配'
    }, {
      value: '4',
      label: '共享'
    }, {
      value: '5',
      label: '转移创建人'
    }, {
      value: '6',
      label: '转移跟进人'
    }, {
      value: '7',
      label: '系统共享'
    }]), "sourcese", ''), "Student", ''), "ClassSearch", ''), "list_typeE", 5), "list_type", 0), "customer_level", ''), "followUpId", ''), "followUpIdES", ''), "followUpEs", {
      //跟进
      name: '',
      theme: '',
      type: 1,
      next_time: ''
    }), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "calendarTypeOptions", [
    //跟进类型
    {
      id: 1,
      label: '电话'
    }, {
      id: 2,
      label: 'QQ'
    }, {
      id: 3,
      label: '微信'
    }, {
      id: 4,
      label: '面聊'
    }]), "currentPage4", 1), "ptionse", 1), "listLoading", true), "tableData", []), "rules", {
      title: [{
        required: true,
        message: '请输入院校名称',
        trigger: 'blur'
      }]
    }), "textMap", {
      update: '编辑院校',
      create: '新建院校'
    }), "dialogStatus", ''), "storageTime", []), "customer_id", []), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "customer_data_id", []), "is_top", ''), "staffResult", []), "staffResultEs", []), "staffResultment", []), "staffResultData", []), "mailListVisible", false), "mailListVisibleEs", false), "mailListVisiblement", false), "mailListVisibleData", false), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "radio", false), "radioEs", false), "radioment", false), "radioData", false), "condition", []), "userId", ''), "transferor", ''), "transferorid", ''), "userName", ''), "value1", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "customer", []), "displaySettings", []), "displaySettingsWhole", []), "dataTiem", ''), "dataEnd", ''), "NumberEs", 2), "dialogTableVisible", false), "callCid", ''), "cusName", ''), "cusPhone", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "sNum", ''), "eNum", ''), "callNumShow", false), "call_num_order", '0');
  },
  mounted: function mounted() {
    var _this = this;
    if (this.course != 1) {
      this.getLabelpeer();
      this.getList(this.query.page, this.query.pagesize);
      // 11111111
      this.callname = this.$store.getters.callname;
      this.callpass = this.$store.getters.callpass;
      var that = this;
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
      this.majorDedMaj(); //意向专业
      this.flowerArray(); //花名
      this.getStructure();
      this.getUserList();
      this.followFun(); //筛选跟进人数据
    } else {}
  },
  components: {
    Head: Head,
    studentLevel: studentLevel,
    mailList: mailList,
    mailListEs: mailListEs,
    mailListData: mailListData,
    mailListment: mailListment,
    DisplaySettingser: DisplaySettingser,
    MyExportExcel: MyExportExcel,
    callRecord: callRecord
  },
  methods: (_methods = {
    getUserList: function getUserList() {
      var _this2 = this;
      _getUserList({
        type: '1',
        get_type: '6'
      }).then(function (res) {
        // res.data.forEach((element,index) => {
        res.data.map(function (item) {
          item.realname = item.name;
          item.children = item.user_arr;
        });
        // });
        _this2.departmentEvel = res.data;
      });
    },
    departmentChange: function departmentChange(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 跟进人所属部门的最后一位ID
      this.departmentName = share;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    followFun: function followFun() {
      var _this3 = this;
      //筛选跟进人数据
      getShareList({
        page: String(this.follow_page),
        pagesize: String(this.follow_pagesize)
      }).then(function (res) {
        _this3.follow_Arr = res.data.data;
      });
    },
    FollowUpClick: function FollowUpClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    followName: function followName() {
      var _this4 = this;
      if (this.follow_name == '') {
        this.follow_page++;
        getShareList({
          page: String(this.follow_page),
          pagesize: String(this.follow_pagesize)
        }).then(function (res) {
          res.data.data.forEach(function (item) {
            _this4.follow_Arr.push(item);
          });
        });
      }
    },
    followMethod: function followMethod(val) {
      var _this5 = this;
      this.follow_name = val;
      this.follow_page = 1;
      getShareList({
        realname: val,
        page: String(this.follow_page),
        pagesize: String(this.follow_pagesize)
      }).then(function (res) {
        _this5.follow_Arr = res.data.data;
      });
    },
    followidEs: function followidEs(val) {
      // this.followName = val
      // this.getList(this.query.page,this.query.pagesize)
    },
    viewRecord: function viewRecord(id, name, phone) {
      this.dialogTableVisible = true;
      this.callCid = id;
      this.cusName = name;
      this.cusPhone = phone;
    },
    callNums: function callNums() {
      //拨打次数筛选
      this.query.page = 1;
      if (this.sNum == '' && this.eNum == '') {
        this.sNum = '';
        this.eNum = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (this.sNum == '' || this.eNum == '') {
        this.$message({
          message: '请输入完整次数段',
          type: 'warning'
        });
      } else if (Number(this.sNum) > Number(this.eNum)) {
        this.$message({
          message: '请输入正确次数范围',
          type: 'warning'
        });
      } else {
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    getList: function getList(page, pagesize) {
      var _this6 = this;
      //账号客户列表

      this.listLoading = true;
      this.resultES = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
        page: String(page),
        pagesize: String(pagesize),
        start_sea_time: this.start_sea_time,
        end_sea_time: this.end_sea_time,
        start_follow_time: this.start_follow_time,
        end_follow_time: this.end_follow_time,
        next_follow_stime: this.next_follow_stime,
        next_follow_etime: this.next_follow_etime,
        user_id: Number(this.userId),
        type: Number(this.sourcese),
        customer_levels: String(this.Student),
        wxnum_mobile_cname: this.ClassSearch,
        list_type: this.list_type,
        create_time_order: this.create_time_order,
        //创建日期排序
        follow_time_order: this.follow_time_order,
        //最后跟进日期排序
        next_time_order: this.next_time_order,
        create_stime: this.create_stime,
        create_etime: this.create_etime,
        source_client_id: this.source_client_id,
        //花名id
        create_realname: this.create_realname,
        labelpeers: this.labelpeers,
        notes: this.remark,
        ittnzy: this.Intended,
        structure_ids: this.structure_idEs
      }, "ittnzy", this.showittnzy), "follow_realname", this.FollowUpEvel), "follow_structuress", this.departmentName), "calls_num", this.sNum), "calle_num", this.eNum), "call_num_order", this.call_num_order);
      MyLoweIndex(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
        page: String(page),
        pagesize: String(pagesize),
        start_sea_time: this.start_sea_time,
        end_sea_time: this.end_sea_time,
        start_follow_time: this.start_follow_time,
        end_follow_time: this.end_follow_time,
        next_follow_stime: this.next_follow_stime,
        next_follow_etime: this.next_follow_etime,
        user_id: Number(this.userId),
        type: Number(this.sourcese),
        customer_levels: String(this.Student),
        wxnum_mobile_cname: this.ClassSearch,
        list_type: this.list_type,
        create_time_order: this.create_time_order,
        //创建日期排序
        follow_time_order: this.follow_time_order,
        //最后跟进日期排序
        next_time_order: this.next_time_order,
        create_stime: this.create_stime,
        create_etime: this.create_etime,
        source_client_id: this.source_client_id,
        //花名id
        create_realname: this.create_realname,
        labelpeers: this.labelpeers,
        notes: this.remark,
        ittnzy: this.Intended,
        structure_ids: this.structure_idEs
      }, "ittnzy", this.showittnzy), "follow_realname", this.FollowUpEvel), "follow_structuress", this.departmentName), "calls_num", this.sNum), "calle_num", this.eNum), "call_num_order", this.call_num_order)).then(function (respomse) {
        var data = respomse.data.list;
        _this6.tableData = data.data;
        _this6.query.total = data.total;
        _this6.listLoading = false;
        _this6.displaySettingsWhole = [];
        _this6.displaySettingsWhole = [];
        _this6.displayOptions = [];
        _this6.exportValueArr = [];
        _this6.displaySettings = respomse.data.fields;
        for (var item in respomse.data.base_fields) {
          _this6.displayOptions.push(respomse.data.base_fields[item]);
          _this6.exportValueArr.push(item);
          _this6.displaySettingsWhole.push(respomse.data.base_fields[item]);
        }
      }).catch(function () {
        _this6.tableData = [];
        _this6.listLoading = false;
      });
      this.flowerArray();
    },
    flowerArray: function flowerArray() {
      var _this7 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this7.flower_Array = res.data;
      });
    },
    // 设置滚动条相关信息1111111111
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    // 客户标签
    getLabelpeer: function getLabelpeer() {
      var _this8 = this;
      _getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (itemEs) {
          itemEs.id = String(itemEs.id);
        });
        _this8.labelpeersEs = res.data;
        _this8.customer = res.data;
      });
    },
    tiemOption: function tiemOption(val) {
      //1进入私海时间 2最后跟进时间
      this.ptionse = val;
      this.publicScreening(val, this.storageTime[0], this.storageTime[1]);
      this.value1 = '';
    },
    screening: function screening(item) {
      //昨天/最近七天/30天
      this.value1 = '';
      this.storageTime = item;
      this.publicScreening(this.ptionse, this.storageTime[0], this.storageTime[1]);
    },
    sourceVal: function sourceVal(val) {
      //来源
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '下次回访日期') {
        this.nexttimeshow = true;
      } else if (item === '创建日期') {
        this.createtimeshow = true;
      } else if (item === '最后跟进日期') {
        this.finalFollowshow = true;
      } else if (item === '跟进人') {
        this.FollowUpShow = true;
      } else if (item === '创建人') {
        this.createshow = true;
      } else if (item === '客户标签') {
        this.labelpeersshow = true;
      } else if (item === '备注') {
        this.noteshow = true;
      } else if (item === '意向专业') {
        this.ittnzyshow = true;
      } else if (item === '组织部门') {
        this.structure_idshow = true;
      } else if (item === '拨打次数') {
        this.callNumShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      if (item === '下次回访日期') {
        this.nexttimeshow = false;
        this.next_follow_stime = '';
        this.next_follow_etime = '';
        this.nexttime = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '创建日期') {
        this.createtimeshow = false;
        this.create_stime = '';
        this.create_etime = '';
        this.value1 = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '最后跟进日期') {
        this.finalFollowshow = false;
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.finalFollow = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '跟进人') {
        this.FollowUpShow = false;
        this.FollowUpEvel = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '创建人') {
        this.createshow = false;
        this.create_realname = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '客户标签') {
        this.labelpeersshow = false;
        this.labelpeers = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '备注') {
        this.noteshow = false;
        this.remark = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '组织部门') {
        this.structure_idshow = false;
        this.structure_id = '';
        this.structure_idEs = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '意向专业') {
        this.ittnzyshow = false;
        this.showittnzy = '';
        this.getList(this.query.page, this.query.pagesize);
      } else if (item === '拨打次数') {
        this.callNumShow = false;
        this.sNum = '';
        this.eNum = '';
        this.getList(this.query.page, this.query.pagesize);
      }
    }
  }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_methods, "callNums", function callNums() {
    //拨打次数筛选
    this.query.page = 1;
    if (this.sNum == '' && this.eNum == '') {
      this.sNum = '';
      this.eNum = '';
      this.getList(this.query.page, this.query.pagesize);
    } else if (this.sNum == '' || this.eNum == '') {
      this.$message({
        message: '请输入完整次数段',
        type: 'warning'
      });
    } else if (Number(this.sNum) > Number(this.eNum)) {
      this.$message({
        message: '请输入正确次数范围',
        type: 'warning'
      });
    } else {
      this.getList(this.query.page, this.query.pagesize);
    }
  }), "sortEs", function sortEs(column, prop, order) {
    // ascending 升序 、 descending降序 、 null 不排序
    if (column.prop == 'create_time') {
      //创建时间
      if (column.order == 'ascending') {
        this.create_time_order = '1';
      } else if (column.order == 'descending') {
        this.create_time_order = '2';
      } else {
        this.create_time_order = '0';
      }
      this.follow_time_order = '0';
      this.next_time_order = '0';
      this.call_num_order = '0';
    } else if (column.prop == 'follw_time') {
      //最后跟进日期
      if (column.order == 'ascending') {
        this.follow_time_order = '1';
      } else if (column.order == 'descending') {
        this.follow_time_order = '2';
      } else {
        this.follow_time_order = '0';
      }
      this.create_time_order = '0';
      this.next_time_order = '0';
      this.call_num_order = '0';
    } else if (column.prop == 'next_time') {
      //下次回访日期
      if (column.order == 'ascending') {
        this.next_time_order = '1';
      } else if (column.order == 'descending') {
        this.next_time_order = '2';
      } else {
        this.next_time_order = '0';
      }
      this.create_time_order = '0';
      this.follow_time_order = '0';
      this.call_num_order = '0';
    } else if (column.prop == 'all_call_num') {
      //拨打次数
      if (column.order == 'ascending') {
        this.call_num_order = '1';
      } else if (column.order == 'descending') {
        this.call_num_order = '2';
      } else {
        this.call_num_order = '0';
      }
      this.create_time_order = '0';
      this.follow_time_order = '0';
      this.next_time_order = '0';
    }
    this.query.page = 1;
    this.getList(this.query.page, this.query.pagesize);
  }), "DateTime", function DateTime(item) {
    var _this9 = this;
    //时间选择
    this.NumberEs = Number(0);
    this.query.page = 1;
    if (this.value1 == null) {
      this.create_stime = '';
      this.create_etime = '';
      this.$nextTick(function () {
        _this9.getList(_this9.query.page, _this9.query.pagesize);
      });
    } else {
      var s_time = this.value1[0].toString();
      this.create_stime = s_time.substring(0, 10);
      var e_time = this.value1[1].toString();
      this.create_etime = e_time.substring(0, 10);
      this.$nextTick(function () {
        _this9.getList(_this9.query.page, _this9.query.pagesize);
      });
    }
  }), "FinalFollowUpEs", function FinalFollowUpEs() {
    var _this10 = this;
    //最后跟进日期筛选
    this.query.page = 1;
    if (this.finalFollow == null) {
      this.start_follow_time = '';
      this.end_follow_time = '';
      this.$nextTick(function () {
        _this10.getList(_this10.query.page, _this10.query.pagesize);
      });
    } else {
      var sf_time = this.finalFollow[0].toString();
      this.start_follow_time = sf_time.substring(0, 10);
      var ef_time = this.finalFollow[1].toString();
      this.end_follow_time = ef_time.substring(0, 10);
      this.$nextTick(function () {
        _this10.getList(_this10.query.page, _this10.query.pagesize);
      });
    }
  }), "nextDateTime", function nextDateTime(item) {
    //回访时间选择
    this.query.page = 1;
    if (this.nexttime == null) {
      this.next_follow_stime = '';
      this.next_follow_etime = '';
      this.getList(this.query.page, this.query.pagesize);
    } else {
      this.next_follow_stime = this.nexttime[0].toString().substring(0, 10);
      this.next_follow_etime = this.nexttime[1].toString().substring(0, 10);
      this.getList(this.query.page, this.query.pagesize);
    }
  }), "flowerArrayEs", function flowerArrayEs(val) {
    this.query.page = 1;
    this.getList(this.query.page, this.query.pagesize);
  }), "shouwittnzyclick", function shouwittnzyclick() {
    this.query.page = 1;
    this.getList(this.query.page, this.query.pagesize);
  }), "customerLabel", function customerLabel(item) {
    //客户标签筛选
    this.query.page = 1;
    this.labelpeers = item;
    this.getList(this.query.page, this.query.pagesize);
  }), "majorDedMaj", function majorDedMaj() {
    var _this11 = this;
    //意向专业默认
    Majorlist().then(function (res) {
      _this11.Intendeds = res.data.major_list;
    });
  }), "structureclick", function structureclick(val) {
    var share = val.map(function (e) {
      return e[e.length - 1];
    }).toString(); // 部门的最后一位ID
    var adad = share == ',' ? '' : share;
    this.structure_idEs = adad;
    this.query.page = 1;
    this.getList(this.query.page, this.query.pagesize);
  }), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_methods, "getStructure", function getStructure() {
    var _this12 = this;
    getStructureListArr().then(function (respomse) {
      _this12.bumenoptions = respomse.data;
    });
  }), "publicScreening", function publicScreening(tab, item1, item2) {
    //公共方法
    this.query.page = 1;
    if (tab == 1) {
      this.start_sea_time = item1;
      this.end_sea_time = item2;
      this.start_follow_time = '';
      this.end_follow_time = '';
      this.getList(this.query.page, this.query.pagesize);
    } else {
      this.start_follow_time = item1;
      this.end_follow_time = item2;
      this.start_sea_time = '';
      this.end_sea_time = '';
      this.getList(this.query.page, this.query.pagesize);
    }
  }), "studentLevel", function studentLevel(val) {
    this.Student = val;
    this.query.page = 1;
    this.getList(this.query.page, this.query.pagesize);
  }), "follower", function follower() {
    this.query.page = 1;
    this.getList(this.query.page, this.query.pagesize);
  }), "creater", function creater() {
    this.query.page = 1;
    this.getList(this.query.page, this.query.pagesize);
  }), "tag", function tag() {
    this.query.page = 1;
    this.getList(this.query.page, this.query.pagesize);
  }), "notes", function notes() {
    this.query.page = 1;
    this.getList(this.query.page, this.query.pagesize);
  }), "CustomerDeliverIndex", function CustomerDeliverIndex() {
    var _this13 = this;
    //外送公海客户
    if (String(this.customer_id) == '') {
      this.$message({
        message: '请选择客户',
        type: 'warning'
      });
    } else {
      _CustomerDeliverIndex({
        customer_ids: String(this.customer_id),
        customer_data_ids: String(this.customer_data_id)
      }).then(function (res) {
        _this13.$notify({
          title: '成功',
          message: '外送公海客户成功',
          type: 'success',
          duration: 2000
        });
        _this13.query.page = 1;
        _this13.onDelayListFun();
      });
    }
  }), "regression", function regression() {
    var _this14 = this;
    //回归公海
    if (String(this.customer_data_id) == '') {
      this.$message({
        message: '请选择客户',
        type: 'warning'
      });
    } else {
      var zifu = '';
      this.$confirm('请确认是否将所选的' + this.customer_data_id.length + '客户回归公海?', '回归公海', {
        confirmButtonText: '继续回归',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        MyCustomerRegression({
          customer_data_ids: String(_this14.customer_data_id)
        }).then(function (res) {
          for (var i in res.data) {
            zifu += "<p>\u5BA2\u6237\uFF1A".concat(res.data[i], "</p>");
          }
          _this14.$notify({
            title: '提示',
            dangerouslyUseHTMLString: true,
            message: zifu
          });
          _this14.query.page = 1;
          _this14.onDelayListFun();
        });
      });
    }
  }), "onDelayListFun", function onDelayListFun() {
    var _this15 = this;
    this.listLoading = true;
    setTimeout(function () {
      _this15.getList(_this15.query.page, _this15.query.pagesize);
    }, 3000);
  }), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_methods, "topOfList", function topOfList(item, id) {
    var _this16 = this;
    //置顶
    MyCustomerTop({
      is_top: item,
      customer_data_id: String(id)
    }).then(function (res) {
      if (item == 1) {
        _this16.$notify({
          title: '成功',
          message: '成功置顶',
          type: 'success',
          duration: 2000
        });
      } else {
        _this16.$notify({
          title: '成功',
          message: '成功取消置顶',
          type: 'success',
          duration: 2000
        });
      }
      _this16.onDelayListFun();
      // this.getList(this.query.page, this.query.pagesize);
    });
  }), "transfer", function transfer() {
    //转移客户
    if (String(this.customer_id) == '') {
      this.$message({
        type: 'info',
        message: '请选择客户'
      });
    } else {
      this.radio = true;
      this.mailListVisible = true;
    }
  }), "followUpPerson", function followUpPerson() {
    //转移客户
    if (String(this.customer_id) == '') {
      this.$message({
        type: 'info',
        message: '请选择客户'
      });
    } else {
      this.radioment = true;
      this.mailListVisiblement = true;
    }
  }), "delCustomer", function delCustomer() {
    var _this17 = this;
    //转移客户
    if (String(this.customer_data_id) == '') {
      this.$message({
        type: 'info',
        message: '请选择客户'
      });
    } else {
      this.$confirm('客户删除后不可恢复，确认删除？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _delCustomer({
          cd_ids: String(_this17.customer_data_id)
        }).then(function (res) {
          _this17.query.page = 1;
          _this17.onDelayListFun();
        });
      });
    }
  }), "share", function share() {
    //共享客户
    if (String(this.customer_id) == '') {
      this.$message({
        type: 'info',
        message: '请选择客户'
      });
    } else {
      this.radioEs = true;
      this.mailListVisibleEs = true;
    }
  }), "staffDepartment", function staffDepartment() {
    //员工部门
    this.radioData = true;
    this.mailListVisibleData = true;
  }), "maillistRadio", function maillistRadio(resultNameArr, result) {
    var _this18 = this;
    //选择转移给谁
    this.transferor = String(result);
    MyCustomerTransfer({
      customer_ids: String(this.customer_id),
      user_id: this.transferor
    }).then(function (res) {
      _this18.$notify({
        title: '提示',
        message: res.data.toString(),
        type: 'success',
        duration: 2000
      });
      _this18.query.page = 1;
      _this18.onDelayListFun();
    });
  }), "staffSelectConfirmEs", function staffSelectConfirmEs(resultNameArr, result) {
    var _this19 = this;
    //选择共享给谁
    this.transferorid = String(result);
    MyShareSetshare({
      customer_ids: String(this.customer_id),
      user_id: this.transferorid
    }).then(function (res) {
      _this19.$notify({
        title: '提示',
        message: res.data.toString(),
        type: 'success',
        duration: 2000
      });
      _this19.query.page = 1;
      _this19.onDelayListFun();
    });
  }), "SharedSustomersData", function SharedSustomersData(resultNameArr, result) {
    //员工部门
    this.query.page = 1;
    this.userId = String(result);
    this.getList(this.query.page, this.query.pagesize);
  }), "SharedSustomersment", function SharedSustomersment(resultNameArr, result) {
    var _this20 = this;
    //选择共享给谁
    this.transferorid = String(result);
    MyCustomerTransfoll({
      customer_data_ids: String(this.customer_data_id),
      user_id: this.transferorid
    }).then(function (res) {
      _this20.$notify({
        title: '提示',
        message: res.data.toString(),
        type: 'success',
        duration: 2000
      });
      _this20.query.page = 1;
      _this20.onDelayListFun();
    });
  }), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_methods, "ClassSearchInput", function ClassSearchInput() {
    if (this.ClassSearch == '') {
      this.getList(this.query.page, this.query.pagesize);
    } else {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize, this.ClassSearch);
    }
  }), "handleSizeChange", function handleSizeChange(val) {
    //每页 ${val} 条
    this.getList(this.query.page, val);
    this.query.pagesize = val;
  }), "handleCurrentChange", function handleCurrentChange(val) {
    //当前页
    this.getList(val, this.query.pagesize);
    this.query.page = val;
  }), "getTimestamp", function getTimestamp(time) {
    //把时间日期转成时间戳
    return new Date(time).getTime() / 1000;
  }), "NewFollowupRecordSure", function NewFollowupRecordSure() {
    var _this21 = this;
    //添加跟进
    if (this.followUpEs.type == '') {
      this.$message({
        type: 'warning',
        message: '请选择跟进类型'
      });
    } else if (this.followUpEs.theme == '') {
      this.$message({
        type: 'warning',
        message: '请输入主题'
      });
    } else {
      FollwupAdd({
        customer_id: Number(this.followUpId),
        customer_data_id: Number(this.followUpIdES),
        content: this.followUpEs.theme,
        type: Number(this.followUpEs.type),
        next_time: this.getTimestamp(this.followUpEs.next_time)
      }).then(function (res) {
        //入学时间
        _this21.$notify({
          title: '成功',
          message: '新增跟进成功',
          type: 'success'
        });
        _this21.onDelayListFun();
        // this.getList(this.query.page, this.query.pagesize);
        _this21.followUpEs.theme = '';
        _this21.followUpEs.type = 0;
        _this21.followUpEs.next_time = '';
        _this21.followUp = false;
      });
    }
  }), "followUpCustomers", function followUpCustomers(id) {
    var _this22 = this;
    //跟进弹窗
    this.activities = '';
    this.followUpEs.type = 1;
    this.followUp = true;
    this.followUpEs.name = id.cname;
    this.followUpId = id.customer_id;
    this.followUpIdES = id.customer_data_id;
    FollwupIndex({
      id: id.customer_id,
      customer_data_id: id.customer_data_id,
      is_realname: 2
    }).then(function (res) {
      res.data.map(function (res) {
        if (res.type == 1) {
          res.type = '电话';
        } else if (res.type == 2) {
          res.type = 'QQ';
        } else if (res.type == 3) {
          res.type = '微信';
        } else if (res.type == 4) {
          res.type = '面聊';
        }
        res.next_time = String(res.next_time);
      });
      _this22.activities = res.data;
    });
  }), "downloadExcel", function downloadExcel(res) {
    var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '未命名.xlsx';
    var a = document.createElement('a');
    var blob = new Blob([res.data], {
      type: 'application/vnd.ms-excel'
    });
    var url = URL.createObjectURL(blob);
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    a.click();
  }), "infodata", function infodata() {
    this.$notify({
      title: '',
      message: '敬请期待',
      type: 'success',
      duration: 2000
    });
  }), "goTodetails", function goTodetails(row, val) {
    //客户详情
    var routeUrl = this.$router.resolve({
      path: '/myCustomerDetail?Cid=' + row.customer_id + '&CData_id=' + row.customer_data_id + '&editRole=1&page=' + this.query.page + '&key=' + val.$index
    });
    window.open(routeUrl.href, '_blank');
  }), "deleteBtn", function deleteBtn() {
    var _this23 = this;
    //删除客户
    if (this.tempId != '') {
      this.$confirm('此操作将永久删除该客户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {}).catch(function () {
        _this23.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    } else {
      this.$message({
        type: 'info',
        message: '请选择院校'
      });
    }
  }), _defineProperty(_methods, "handleSelectionChange", function handleSelectionChange(val) {
    //获取选中id
    var idEs = [];
    var dataId = [];
    for (var i = 0; i < val.length; i++) {
      dataId.push(val[i].customer_data_id);
      idEs.push(val[i].customer_id);
      this.is_top = val[i].is_top;
      this.userName = val[i].cname;
    }
    this.customer_data_id = _toConsumableArray(new Set(dataId));
    this.customer_id = _toConsumableArray(new Set(idEs));
    // 单选时打开此代码
    // if (val.length > 1) {
    //     this.$refs.multipleTable.clearSelection();
    //     this.$refs.multipleTable.toggleRowSelection(val.pop());
    // }
  }))
};